<template>
  <admin>
    <page-header :back="{ to: { name: 'survey' }, text: trans('Surveys') }">
      <template v-slot:title
        ><span>{{ title }} Results</span></template
      >
      <template v-slot:action>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          large
          color="grey"
          class="white--text"
          exact
          @click="download"
          :loading="downloadLoading"
          :disabled="downloadLoading"
        >
          <v-icon small left>mdi-cloud-download</v-icon>
          <span v-text="'Download'"></span>
        </v-btn>
      </template>
    </page-header>

    <v-card>
      <can :code="['admin', 'admin_survey']">
        <survey-filter
          ref="toolbar"
          :advanceFilter="true"
          :filterDateRange="true"
          :filterRegions="true"
          :filterGroups="true"
          :filterZones="true"
          :filterDealer="true"
          :isSearchColsAuto="true"
          :items.sync="tabletoolbar"
          @update:search="setSearch"
          @update:daterangesurvey="setDateRange"
          @update:regions="setRegions"
          @update:zones="setZones"
          @update:groups="setGroups"
          @update:dealer="setDealer"
        />
      </can>

      <can :code="['dealer_survey']">
        <survey-filter
          ref="toolbar"
          :advanceFilter="false"
          :filterDateRange="true"
          :filterRegions="true"
          :filterGroups="true"
          :filterZones="true"
          :filterDealer="true"
          :isSearchColsAuto="true"
          :items.sync="tabletoolbar"
          @update:search="setSearch"
          @update:daterangesurvey="setDateRange"
          @update:regions="setRegions"
          @update:zones="setZones"
          @update:groups="setGroups"
          @update:dealer="setDealer"
        />
      </can>

      <v-data-table
        :loading="resources.loading"
        :headers="resources.headers"
        :items="items"
        :items-per-page="5"
        :server-items-length="resources.total"
        :options.sync="resources.options"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            text
            color="primary"
            icon
            v-on="on"
            @click="showSurveyResult(item.id)"
          >
            {{ item.id }}
          </v-btn>
        </template>

        <template v-slot:item.id="{ item }">
          <div class="text-no-wrap">
            <v-btn @click="showSurveyResult(item.id)" text color="primary" icon>
              {{ item.id }}
            </v-btn>
          </div>
        </template>

        <template v-slot:item.customer_fullname="{ item }">
          {{ item.fullname }} <br />
          <small>{{ item.mobile }}</small> <br />
          <small>{{ item.email }}</small>
        </template>

        <template v-slot:item.created_at="{ item }">
          <span>{{ formatDate(item.created_at) }}</span>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- View Details -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon v-on="on" @click="showSurveyResult(item.id)">
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span v-text="'View Details'"></span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <template v-if="previewDialog">
      <v-dialog
        v-model="previewDialog"
        max-width="900px"
        class="overflow-hidden"
      >
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Survey Result" class="mb-1">#{{ dialogItem.id }}</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <!-- Background Details -->
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Customer" }}
                  </td>
                  <td>{{ dialogItem.title ? dialogItem.title : ''  }} {{ `${(dialogItem.fullname) ? dialogItem.fullname : ''}` }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Mobile" }}
                  </td>
                  <td>
                    {{ dialogItem.mobile }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Email" }}
                  </td>
                  <td>
                    {{ dialogItem.email }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Model & Year" }}
                  </td>
                  <td>
                    {{ dialogItem.model }} {{ dialogItem.year_model ? dialogItem.year_model : '' }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "CS Number / Plate Number" }}
                  </td>
                  <td>
                    {{ dialogItem.cs_number }} {{ (dialogItem.cs_number && dialogItem.plate_number) ? "/"  : "" }} {{ dialogItem.plate_number }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Dealer" }}
                  </td>
                  <td>
                    {{ dialogItem.dealer_name }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <hr style="border-top: 1px solid var(--sidebar-border)" />
            <br />
            <v-simple-table>
              <tbody>
                <tr
                  v-for="(entry, name, index) in dialogItem.response_data"
                  :key="index"
                >
                  <td style="width: 50%" class="font-weight-bold">
                    <small>{{ entry.title }}</small>
                  </td>
                  <td
                    class="text-center"
                    v-if="Array.isArray(entry.value)"
                    style="width: 50%"
                  >
                    <v-chip v-for="v in entry.value" class="mr-1" :key="v">{{
                      v
                    }}</v-chip>
                  </td>
                  <td
                    class="text-center"
                    v-else-if="
                      typeof entry.value === 'object' &&
                      entry.value !== null &&
                      !Array.isArray(entry.value)
                    "
                    style="width: 50%"
                  >
                    <small
                      v-for="(v1, n, i) in entry.value"
                      class="d-block mr-1"
                      :key="i"
                      ><strong>{{ n }}</strong> - {{ v1 }}</small
                    >
                  </td>
                  <td class="text-center" v-else style="width: 50%">
                    {{ entry.value }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <!-- Background Details -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              color="grey"
              exact
              class="ma-1 white--text px-5"
              @click="previewDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </admin>
</template>
<script>
import * as helpers from "@/core/helpers";
import { mapActions, mapGetters } from "vuex";
import { debounce } from "lodash";

export default {
  data() {
    return {
      tabletoolbar: {
        isSearching: false,
        search: null,
        toggleBulkEdit: false,
        bulkCount: 0,
        actionDropdownItems: [
          {
            slug: "activate",
            action: "Activate",
          },
          {
            slug: "deactivate",
            action: "Deactivate",
          },
          {
            slug: "delete",
            action: "Delete",
          },
        ],
      },
      resources: {
        headers: [
          {
            text: "ID",
            align: "center",
            value: "id",
          },
          {
            text: "Customer Information",
            align: "left",
            value: "customer_fullname",
          },
          {
            text: "Date Created",
            align: "left",
            value: "created_at",
          },
          {
            text: "Action",
            align: "left",
            value: "action",
          },
        ],
        total: 5,
        loading: true,
        options: {},
      },
      items: [],
      options: {},
      previewDialog: false,
      dialogItem: {},
      resendLoading: false,
      downloadLoading: false,
      title: "",
      filters: {
        q: "",
        date: [],
        regions: "",
        zones: "",
        groups: "",
        dealers: "",
      },
    };
  },
  watch: {
    "resources.options": {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;

        this.getSurveyResults(page, itemsPerPage);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      results: "survey/GET_SURVEY_RESULTS",
      result: "survey/GET_SURVEY_RESULT",
    }),
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/show",
      getResultsPerSurvey: "survey/getResultsPerSurvey",
      findSurveyResult: "survey/findSurveyResult",
      getDealerZones: "dealerZone/getDealerZones",
      getDealerGroups: "dealerGroup/getDealerGroups",
      getDealers: "dealer/getDealers",
      downloadSurveyResult: "survey/downloadSurveyResult",
    }),
    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.filters.q = e.target.value;

      this.getSurveyResults(1, itemsPerPage);
    }, 300),
    async download() {
      let self = this;
      self.downloadLoading = true;

      await this.downloadSurveyResult({
        q: this.filters.q,
        survey_id: this.$route.params.id,
        date: this.filters.date,
        regions: [this.filters.regions],
        zones: [this.filters.zones],
        groups: [this.filters.groups],
        dealers: [this.filters.dealers],
      }).then(function () {
        self.downloadLoading = false;
      });
    },
    async getSurveyResults(page, itemsPerPage) {
      const self = this;

      self.resources.loading = true;

      await self
        .getResultsPerSurvey({
          page: page,
          per_page: itemsPerPage,
          q: this.filters.q,
          survey_id: this.$route.params.id,
          date: this.filters.date,
          regions: this.filters.regions ? [this.filters.regions] : [],
          zones: this.filters.zones ? [this.filters.zones] : [],
          groups: this.filters.groups ? [this.filters.groups] : [],
          dealers: this.filters.dealers ? [this.filters.dealers] : [],
        })
        .then(function () {
          self.resources.loading = false;
          self.items = self.results.data;
          self.tabletoolbar.isSearching = false;
          self.resources.total =
            itemsPerPage > 0
              ? self.results.meta.total
              : self.results.data.length;
        });
    },
    formatDate(date) {
      return helpers.format_date(date);
    },
    showSurveyResult(id) {
      const self = this;
      self.findSurveyResult(id).then(function () {
        self.dialogItem = self.result;
        self.previewDialog = true;
      });
    },
    setDateRange(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.filters.date = e;

      this.getSurveyResults(1, itemsPerPage);
    },
    async setRegions(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.filters.regions = e;

      //Set zone filter
      await this.getDealerZones([this.filters.regions]);

      //Set groups filter
      this.filters.zones = "";
      await this.getDealerGroups({
        region_types: [this.filters.regions],
        zones: [this.filters.zones],
      });

      //Set dealer filter
      this.filters.groups = "";
      await this.getDealers({
        region_types: [this.filters.regions],
        zones: [this.filters.zones],
        groups: [this.filters.groups],
      });

      //Get results
      await this.getSurveyResults(1, itemsPerPage);
    },
    async setZones(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.filters.zones = e ? e.value : null;

      //Set groups filter
      await this.getDealerGroups({
        region_types: [this.filters.regions],
        zones: [this.filters.zones],
      });

      //Set dealer filter
      this.filters.groups = "";
      this.filters.dealers = "";

      await this.getDealers({
        region_types: [this.filters.regions],
        zones: [this.filters.zones],
        groups: [this.filters.groups],
      });

      // Get results
      await this.getSurveyResults(1, itemsPerPage);
    },
    async setGroups(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.filters.groups = e ? e.value : null;

      //Set dealer filter
      this.filters.dealers = "";

      await this.getDealers({
        region_types: [this.filters.regions],
        zones: [this.filters.zones],
        groups: [this.filters.groups],
      });

      // Get results
      await this.getSurveyResults(1, itemsPerPage);
    },
    async setDealer(e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.filters.dealers = e;

      // Get results
      await this.getSurveyResults(1, itemsPerPage);
    },
  },
  mounted() {
    this.title = this.$route.params.name;
    const self = this;
  },
};
</script>
